import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { styled, media, angle, pink } from "../../styles";
import { Page, Content, TitleImage, List, ListItem } from "../../components";
import { TeamMemberInterface } from "./TeamMemberInterface";
import { Polaroid } from "./components";
import headerImg from "./assets/Illu_Team.svg";
import schwertImg from "./assets/Schwert_Skeletor.png";

export const TeamPage: React.FC<{}> = props => {
  const {
    team: { edges: teamMemberNodes }
  }: {
    team: {
      edges: [
        {
          node: TeamMemberInterface;
        }
      ];
    };
  } = useStaticQuery(graphql`
    query teamQuery {
      team: allTeamJson {
        edges {
          node {
            id
            name
            owner
            position
            video
          }
        }
      }
    }
  `);
  const initialTeamMember =
    typeof window !== "undefined"
      ? teamMemberNodes[Math.floor(Math.random() * teamMemberNodes.length)].node
      : undefined;

  const [selectedTeamMember, setTeamMember] = useState<TeamMemberInterface>(
    initialTeamMember
  );

  const teamMembers: TeamMemberInterface[] = [];

  teamMemberNodes.map(({ node: teamMember }) => teamMembers.push(teamMember));

  const halfWayThough = Math.floor(teamMembers.length / 2);

  const teamMembers1stHalf = teamMembers.slice(0, halfWayThough);
  const teamMembers2ndHalf = teamMembers.slice(
    halfWayThough,
    teamMembers.length
  );

  return (
    <Page
      title="Team"
      description="Hammer:  Das ist German Wahnsinn!"
      seoTitle="Team | German Wahnsinn"
      bgPaper="graph"
      headerSkull="Team"
      sideLabel={false}
      {...props}
    >
      <Content>
        <TeamContainer>
          <TeamTitleImage title="Team" src={headerImg} />

          <Polaroid teamMember={selectedTeamMember} />

          <TeamMemberListContainer>
            <TeamMemberListFirst>
              {teamMembers1stHalf.map(teamMember => (
                <TeamListItem key={teamMember.name}>
                  <a
                    onMouseEnter={() => setTeamMember(teamMember)}
                    className={
                      selectedTeamMember &&
                      selectedTeamMember.name &&
                      teamMember.name === selectedTeamMember.name
                        ? "active"
                        : undefined
                    }
                  >
                    {teamMember.name}
                  </a>
                </TeamListItem>
              ))}
            </TeamMemberListFirst>

            <TeamMemberListSecond>
              {teamMembers2ndHalf.map(teamMember => (
                <TeamListItem key={teamMember.name}>
                  <a
                    onMouseEnter={() => setTeamMember(teamMember)}
                    className={
                      selectedTeamMember &&
                      selectedTeamMember.name &&
                      teamMember.name === selectedTeamMember.name
                        ? "active"
                        : undefined
                    }
                  >
                    {teamMember.name}
                  </a>
                </TeamListItem>
              ))}
            </TeamMemberListSecond>
          </TeamMemberListContainer>

          <Sword src={schwertImg} alt="Skeletor Sword" />
        </TeamContainer>
      </Content>
    </Page>
  );
};

const TeamContainer = styled.div`
  pointer-events: none;

  ${media.landscape} {
    min-height: 900px;
  }

  ${media.tablet} {
    margin-top: 5rem;
    min-height: 900px;
  }

  ${media.landscape} {
    min-height: 900px;
  }

  ${media.laptop} {
    min-height: 600px;
  }

  ${media.desktop} {
    min-height: 900px;
  }
`;

const TeamTitleImage = styled(TitleImage)`
  width: 130%;
  margin-left: -10%;

  ${media.landscape} {
    margin-left: 0;
    width: 90%;
    margin-top: 0;
  }

  ${media.tablet} {
    margin-top: -10%;
    margin-left: 5%;
    width: 90%;
  }

  ${media.laptop} {
    width: 50%;
    margin-left: 20%;
    margin-top: 120px;
  }
`;

const TeamMemberListContainer = styled.div`
  pointer-events: none;
  z-index: 5;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  position: relative;

  ${media.tablet} {
    position: absolute;
    min-height: 1100px;
    flex-direction: column;

    top: 0;
  }

  ${media.landscape} {
    position: absolute;
    min-height: 1100px;
    flex-direction: column;

    top: 0;
  }
`;

const TeamMemberListFirst = styled(List)`
  pointer-events: all;
  width: 50%;
  position: relative;
  ${angle(-6)};

  left: -15px;

  z-index: 5;

  ${media.tablet} {
    position: absolute;
    left: 60px;
    top: 300px;
  }

  ${media.landscape} {
    position: absolute;
    left: 40px;
    top: 20%;
  }

  ${media.laptop} {
    left: 50px;
    top: 65px;
  }
`;

const TeamMemberListSecond = styled(List)`
  pointer-events: all;
  width: 50%;
  position: relative;
  z-index: 5;
  right: -30px;
  text-align: left;
  ${angle(13)};

  ${media.tablet} {
    position: absolute;
    width: auto;
    left: auto;
    right: 10px;
    top: 100px;
  }

  ${media.landscape} {
    position: absolute;
    width: auto;
    left: auto;
    right: 10px;
    top: 100px;
  }

  ${media.laptop} {
    left: 80px;
    right: 55%;
    top: 33%;
    text-align: right;
  }

  ${media.desktop} {
    top: 450px;
  }
`;

const TeamListItem = styled(ListItem)`
  font-size: 17px;
  line-height: 20px;

  ${media.tablet} {
    font-size: 20px;
    line-height: 25px;
  }

  ${media.laptop} {
    font-size: 23px;
    line-height: 28px;
  }

  ${media.desktop} {
    font-size: 25px;
    line-height: 30px;
  }

  a {
    font-variation-settings: "wght" 520;

    &:hover,
    .active {
      color: ${pink};
      animation: wahnsinn 0.3s linear 0s infinite;
    }
  }
`;

const Sword = styled.img`
  display: none;

  ${media.laptop} {
    display: block;
    position: absolute;
    width: 380px;
    bottom: -20%;
    left: -10%;
    z-index: 1;
  }
`;
