import React, { useRef, useCallback } from "react";
import { Trans } from "@lingui/macro";
import posed from "react-pose";
import { styled, media, fonts } from "../../../styles";
import { TeamMemberInterface } from "../TeamMemberInterface";
import polaroidImg from "../assets/Polaroid_Pin.png";
import placeholderImg from "../assets/placeholder.svg";

interface IProps {
  teamMember?: TeamMemberInterface;
}

export const Polaroid: React.FC<IProps> = ({ teamMember }) => {
  const videoRef = useRef<HTMLVideoElement>();
  const onClick = useCallback(() => {
    if (videoRef && videoRef.current) {
      videoRef.current.play();
    }
  }, [videoRef.current]);

  return (
    <PolaroidContainer>
      <PolaroidFrame>
        {teamMember && (
          <>
            <Video
              onClick={onClick}
              ref={videoRef}
              key={teamMember.name}
              initialPose="exit"
              pose="enter"
              autoPlay={true}
              loop={true}
              muted={true}
              autoBuffer={true}
              playsInline={true}
              controls={false}
              preload="auto"
            >
              <source
                src={`/videos/team/${teamMember.video}-960.mp4`}
                type="video/mp4"
              />
            </Video>

            <TeamInfo>
              <strong>{teamMember.name}</strong>
              <br />
              {teamMember.position}
              {teamMember.owner && (
                <>
                  <br />
                  <Trans>owner</Trans>
                </>
              )}
            </TeamInfo>
          </>
        )}
      </PolaroidFrame>
    </PolaroidContainer>
  );
};

/*
    width: 100%;
    padding-top: 116.513%;

    padding-top: 113%;
*/
const PolaroidContainer = styled.div`
  position: relative;
  width: 150%;

  /* padding-top: 166.513%; */
  padding-top: 169.5%;

  height: 0;
  margin-left: -40px;
  margin-top: -20%;

  overflow: hidden;
  z-index: 1;

  ${media.landscape} {
    width: 90%;
    /* padding-top: 104.8617%; */
    padding-top: 101.7%;
    position: absolute;
    top: 250px;
    right: -10%;
    margin-left: -30%;
    margin-top: 0;
  }

  ${media.tablet} {
    width: 90%;
    /* padding-top: 104.8617%; */
    padding-top: 101.7%;
    position: absolute;
    top: 250px;
    right: -25%;
    margin-left: -30%;
    margin-top: 0;
  }

  ${media.landscape} {
    width: 70%;
    margin-top: 1rem;
    /* padding-top: 81.5591%; */
    padding-top: 79.1%;

    position: absolute;
    top: 250px;
    right: -5%;
    margin-left: -30%;
    margin-top: 0;
  }

  ${media.laptop} {
    width: 50%;
    margin-top: 1rem;
    /* padding-top: 81.5591%; */
    padding-top: 58.2565%;
    top: 0;
    right: -5%;
  }

  ${media.desktop} {
    width: 70%;
    margin-top: 1rem;
    /* padding-top: 81.5591%; */
    padding-top: 79.1%;
    top: 0;
    right: -15%;
  }
`;

const PolaroidFrame = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-image: url("${polaroidImg}");
  background-size: 100% 100%;
  background-position: center top;
  
  padding-top: 17%;
  padding-left: 13%;
  padding-right: 22%;

  &::before {
    content: "";

    width: auto;
    height: auto;

    position: absolute;

    top: 15%;
    bottom: 28%;
    left: 10%;
    right: 20%;
    z-index: -1;

    background-color: black;
    background-image: url("${placeholderImg}");
    background-size: 100% 100%;    
  }
  `;

const Video = styled(
  posed.video({
    enter: {
      opacity: 1,
      transition: { duration: 750, ease: "easeIn" }
    },
    exit: {
      opacity: 0
    }
  })
)`
  position: relative;
  pointer-events: all;
  width: 100%;
  height: 67%;
  z-index: -1;
`;

const TeamInfo = styled.div`
  z-index: 1;
  position: relative;
  width: 99%;
  font-family: ${fonts.vario};
  text-transform: uppercase;

  text-align: center;
  font-size: 14px;
  margin-top: -5px;

  strong {
    font-size: 16px;
  }

  ${media.tablet} {
    font-size: 20px;

    strong {
      font-size: 22px;
    }
  }

  ${media.laptop} {
    font-size: 16px;

    strong {
      font-size: 20px;
    }
  }

  ${media.desktop} {
    font-size: 28px;

    strong {
      font-size: 30px;
    }
  }
`;
